import * as React from "react";

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  ShowButton,
  Show,
  SimpleShowLayout,
  useRecordContext,
  TextInput,
} from "react-admin";

const ProductTitle = () => {
  const record = useRecordContext();
  return <span>Product: {record ? `${record.product_name}` : ""}</span>;
};

const productFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const ProductList = (props) => (
  <List {...props} filters={productFilters} perPage={25}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="product_code" />
      <TextField source="customer_name" />
      <TextField source="internal_reference" />
      <NumberField source="amount" />
      <NumberField source="charge" />
      <TextField source="status" />
      <DateField source="create_at" />
      <DateField source="updated_at" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ProductShow = () => (
  <Show title={<ProductTitle />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <NumberField source="amount" />
      <NumberField source="charge" />
      <TextField source="status" />
      <TextField source="reference" />
      <TextField source="msisdn" />
      <TextField source="product_code" />
      <TextField source="location_id" />
      <TextField source="contact_phone" />
      <TextField source="description" />
      <TextField source="validation_reference" />
      <TextField source="customer_name" />
      <TextField source="product_name" />
      <TextField source="internal_reference" />
      <DateField source="create_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);
