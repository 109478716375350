import * as React from "react";

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  ShowButton,
  Show,
  SimpleShowLayout,
  useRecordContext,
  TextInput,
} from "react-admin";

const CollectionTitle = () => {
  const record = useRecordContext();
  return (
    <span>Collection: {record ? `${record.internal_reference}` : ""}</span>
  );
};

const collectionFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const CollectionList = (props) => (
  <List {...props} filters={collectionFilters} perPage={25}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="reference" />
      <TextField source="internal_reference" />
      <TextField source="currency" />
      <NumberField source="amount" />
      <TextField source="status" />
      <DateField source="create_at" />
      <DateField source="updated_at" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const CollectionShow = () => (
  <Show title={<CollectionTitle />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="reference" />
      <TextField source="msisdn" />
      <TextField source="internal_reference" />
      <TextField source="currency" />
      <NumberField source="amount" />
      <TextField source="status" />
      <TextField source="description" />
      <DateField source="create_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);
