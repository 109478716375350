import * as React from "react";

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  ShowButton,
  Show,
  Tab,
  TabbedShowLayout,
  ReferenceManyField,
  Create,
  SimpleShowLayout,
  useRecordContext,
} from "react-admin";
import Box from "@mui/material/Box";

const ServiceTitle = () => {
  const record = useRecordContext();
  return (
    <span>Service: {record ? `${record.name} | ${record.code}` : ""}</span>
  );
};

const serviceFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const ServiceList = (props) => (
  <List {...props} filters={serviceFilters} perPage={25}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="code" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const Aside = () => (
  <Box sx={{ width: "30%", margin: "1em" }}>
    <p>Service Details</p>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="code" />
      <DateField source="created_at" />
      <TextField source="updated_at" />
    </SimpleShowLayout>
  </Box>
);

export const ServiceShow = () => (
  <Show aside={<Aside />} title={<ServiceTitle />}>
    <ReferenceManyField reference="charges" target="service_id">
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="service_id" />
        <TextField source="min_amount" />
        <TextField source="max_amount" />
        <NumberField source="fee" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Datagrid>
    </ReferenceManyField>
  </Show>
);

export const ServiceEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="code" />
    </SimpleForm>
  </Edit>
);

export const ServiceCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
    </SimpleForm>
  </Create>
);
