import {
  Datagrid,
  DateField,
  List,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  DateInput,
  Edit,
  SimpleForm,
  TextInput,
  ShowButton,
  SelectInput,
  EditButton,
} from "react-admin";

export const AdminList = () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="username" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const AdminCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="username" />
      <TextInput source="password" />
      <TextInput source="picture" />
      <SelectInput
        source="role"
        choices={[
          { id: "admin", name: "admin" },
          { id: "superAdmin", name: "superAdmin" },
        ]}
      />
    </SimpleForm>
  </Create>
);

export const AdminEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="username" />
      <TextInput source="password" defaultValue="Input safe password" />
      <TextInput source="picture" />
      <SelectInput
        source="role"
        choices={[
          { id: "admin", name: "admin" },
          { id: "superAdmin", name: "superAdmin" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const AdminShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="picture" />
      <TextField source="role" />
      <DateField source="created_at" />
      <TextField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);
