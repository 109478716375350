import * as React from "react";
import { fetchUtils, Admin, Resource, CustomRoutes } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { AdminList, AdminEdit, AdminCreate, AdminShow } from "./admins";
import { SettingEdit, SettingList, SettingShow } from "./settings";
import { ChargeEdit, ChargeList, ChargeShow, ChargeCreate } from "./charges";
import { CollectionList, CollectionShow } from "./collections";
import { ProductList, ProductShow } from "./products";
import {
  ServiceEdit,
  ServiceList,
  ServiceShow,
  ServiceCreate,
} from "./services";
import Dashboard from "./Dashboard";
import authProvider from "./authProvider";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add token to header
  const token = localStorage.getItem("auth");
  options.headers.set("Authorization", `Bearer ${token}`);
  // add your own headers here
  options.headers.set("Access-Control-Expose-Headers", "X-Total-Count");
  options.headers.set("Access-Control-Allow-Origin", true);

  return fetchUtils.fetchJson(url, options);
};

const apiUrl = process.env.REACT_APP_API_URL;

const dataProvider = jsonServerProvider(apiUrl, httpClient);

const App = () => (
  <Admin
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
  >
    <Resource
      name="admins"
      list={AdminList}
      edit={AdminEdit}
      show={AdminShow}
      create={AdminCreate}
      icon={ManageAccountsIcon}
    />
    <Resource
      name="settings"
      list={SettingList}
      edit={SettingEdit}
      show={SettingShow}
      icon={SettingsIcon}
    />
    <Resource
      name="services"
      list={ServiceList}
      edit={ServiceEdit}
      create={ServiceCreate}
      show={ServiceShow}
      icon={SettingsIcon}
    />
    <Resource
      name="charges"
      list={ChargeList}
      edit={ChargeEdit}
      create={ChargeCreate}
      show={ChargeShow}
      icon={SettingsIcon}
    />
    <Resource
      name="collections"
      list={CollectionList}
      show={CollectionShow}
      icon={SettingsIcon}
    />
    <Resource
      name="products"
      list={ProductList}
      show={ProductShow}
      icon={SettingsIcon}
    />
  </Admin>
);

export default App;
