import * as React from "react";

import {
  Datagrid,
  DateField,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  ShowButton,
  Show,
  SimpleShowLayout,
  Toolbar,
  SaveButton,
} from "react-admin";

const SettingEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const SettingList = (props) => (
  <List {...props} perPage={25}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="percentage_fee" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const SettingShow = () => (
  <>
    <Show title="Setting Details">
      <SimpleShowLayout>
        <TextField source="percentage_fee" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  </>
);

export const SettingEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<SettingEditToolbar />}>
      <TextInput disabled source="id" />
      <TextField source="percentage_fee" />
    </SimpleForm>
  </Edit>
);
