import * as React from "react";

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  EditButton,
  ReferenceField,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ShowButton,
  Show,
  Tab,
  TabbedShowLayout,
  ReferenceManyField,
  Create,
  SimpleShowLayout,
  useRecordContext,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import Box from "@mui/material/Box";

const ChargeTitle = () => {
  const record = useRecordContext();
  return <span>Charge: {record ? `${record.name} | ${record.code}` : ""}</span>;
};

export const ChargeList = (props) => (
  <List {...props} perPage={25}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="service_id" reference="services" link={true}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="min_amount" />
      <TextField source="max_amount" />
      <TextField source="fee" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const ChargeShow = () => (
  <Show title={<ChargeTitle />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="service_id" reference="services" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="min_amount" />
      <NumberField source="max_amount" />
      <NumberField source="fee" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export const ChargeEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="service_id" />
      <NumberInput source="min_amount" />
      <NumberInput source="max_amount" />
      <NumberInput source="fee" />
    </SimpleForm>
  </Edit>
);

export const ChargeCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="service_id" reference="services">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="min_amount" />
      <NumberInput source="max_amount" />
      <NumberInput source="fee" />
    </SimpleForm>
  </Create>
);
